import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Flex, Box } from 'components/Layout'
import LocalImage from 'components/LocalImage'

import imageHeader from 'static/images/engagement/header/header_picture.webp'
import imageHeaderMessage from 'static/images/engagement/header/message.webp'
import imageHeaderButton from 'static/images/engagement/header/button.webp'
import imageHeaderAnswer from 'static/images/engagement/header/answer.webp'

const StyledImageHeader = styled(LocalImage)`
  position: absolute;
`

const StyledImageHeaderMessage = styled(StyledImageHeader)`
  box-shadow: 0px 10.6536px 11.8373px rgba(14, 28, 56, 0.07);
  backdrop-filter: blur(6px);
  ${({ coefficient }) => `  
  top: ${coefficient * 195}px;
  right: ${coefficient * 15}px;
  width: ${coefficient * 511}px;
  height: ${coefficient * 69}px`};
  animation: engagement-image-header-message 8s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite both;
  @keyframes engagement-image-header-message {
    0% {
      transform: translateY(0) translateX(0);
    }
    16.6666666667% {
      transform: scale(1.06) translateY(-8px) translateX(-16px);
    }
    33.3333333333% {
      transform: scale(1.06) translateY(-8px) translateX(-16px);
    }
    50.0000000001% {
      transform: scale(1.12) translateY(-16px) translateX(-16px);
    }
    66.6666666668% {
      transform: scale(1.12) translateY(-16px) translateX(-16px);
    }
    83.3333333335% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`

const StyledImageHeaderButton = styled(StyledImageHeader)`
  box-shadow: 7px 10px 20px rgba(1, 90, 255, 0.25);
  border-radius: 6px;
  cursor: pointer;

  ${({ coefficient }) => `  
  right: ${coefficient * 55}px;
  bottom: ${coefficient * 70}px;
  width: ${coefficient * 138}px;
  height: ${coefficient * 34}px`};
  animation: engagement-image-header-button 4s linear infinite;
  @keyframes engagement-image-header-button {
    0%,
    3% {
      transform: rotateZ(0);
    }
    8% {
      transform: rotateZ(-15deg);
    }
    10% {
      transform: rotateZ(10deg);
    }
    12% {
      transform: rotateZ(-10deg);
    }
    15% {
      transform: rotateZ(6deg);
    }
    18% {
      transform: rotateZ(-4deg);
    }
    20%,
    100% {
      transform: rotateZ(0);
    }
  }
`

const StyledImageHeaderAnswer = styled(StyledImageHeader)`
  box-shadow: 0px 9.40898px 16px rgba(14, 28, 56, 0.07);
  backdrop-filter: blur(3px);

  ${({ coefficient }) => `  
  left: ${coefficient * 73}px;
  bottom: ${coefficient * -25}px;
  width: ${coefficient * 355}px;
  height: ${coefficient * 62}px`};

  animation: engagement-image-header-answer 8s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite both;
  @keyframes engagement-image-header-answer {
    0% {
      transform: translateX(0);
    }
    16.6666666667% {
      transform: translateX(8px);
    }
    33.3333333333% {
      transform: translateX(8px);
    }
    50.0000000001% {
      transform: translateX(-8px);
    }
    66.6666666668% {
      transform: translateX(-8px);
    }
    83.3333333335% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(0px);
    }
  }
`

const HeaderImageAnimation = () => {
  // this is a coefficient of the image. Real size 500px, but on the site it is 400px, so the coefficient would be 400/500
  const [coefficient, setCoefficient] = useState(1)
  const imageWrapperRef = useRef(null)

  const countCoefficient = () => {
    if (imageWrapperRef && imageWrapperRef.current) {
      const { current: { clientWidth, firstChild: { naturalWidth } = {} } = {} } = imageWrapperRef
      const newCoefficient = (clientWidth * 2) / naturalWidth
      if (!Number.isNaN(newCoefficient) && newCoefficient > 0) {
        setCoefficient(newCoefficient)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', countCoefficient)

    return () => {
      window.removeEventListener('resize', countCoefficient)
    }
  }, [])

  return (
    <Flex width="100%">
      <Flex maxWidth="554px" mx="auto" pb={{ mobile: 'l', desktop: 0 }}>
        <Box width="100%" position="relative" ref={imageWrapperRef}>
          <LocalImage
            src={imageHeader}
            width="100%"
            mx="auto"
            alt="Build your brand by Scaling Engagement"
            onLoad={() => {
              countCoefficient()
            }}
          />

          <StyledImageHeaderMessage src={imageHeaderMessage} coefficient={coefficient} />
          <StyledImageHeaderButton
            src={imageHeaderButton}
            coefficient={coefficient}
            onClick={() => {
              window.open('/pricing', '_self')
            }}
          />
          <StyledImageHeaderAnswer src={imageHeaderAnswer} coefficient={coefficient} />
        </Box>
      </Flex>
    </Flex>
  )
}

export default HeaderImageAnimation
